export default function Navbar() {
    return <nav className="navbar">     
    <div className="logo">
        <div><a className="logotext" href="/">BUSTADKLUBBEN</a></div>
        <div><a className="logotext2" href="/">I NORHEIMSUND</a></div>
    </div>

    <input id="toggle" type="checkbox" />
    <label className="hamburger-menu" htmlFor="toggle">
        <div className="bun top"></div>
        <div className="bun mid"></div>
        <div className="bun bot"></div>
    </label>
    <ul className="menu-items">
        <li className="nav-item"><a href="/">Heim </a></li>
        <li className="nav-item"><a href="/styret">Styret</a></li>
        <li className="nav-item"><a href="/medlem">Medlem</a></li>
        <li className="nav-item"><a href="/vedtekter">Vedtekter</a></li>
        <li className="nav-item"><a href="/info">Info</a></li>
        <li className="tilsals-btn"><a href="/tilsals">Til sals</a></li>
    </ul>
</nav>
}