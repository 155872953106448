import img from "../static/img/Norheimsundoversikt.jpg";
import styles from "../static/styles/index.module.css";
let scrolled;
export let isHomePage;
window.addEventListener('scroll', () => {
    
    if (window.pageYOffset > 150 && !scrolled && isHomePage === true) {
        scrolled = true;
        const contentpage = document.querySelector(".content-page")
        contentpage.style.marginTop = "-100px";
        contentpage.style.visibility = "unset";
        contentpage.style.position = "unset";
        contentpage.style.opacity = "1";
        document.querySelector(".footer").style.display = "flex";
        console.log("if statement toggled")
    
    } 
    
    
})

export default function Home() {
    isHomePage = true;
    
      

    return <>
    <div className={styles.bg}></div>
    <div className="welcome-text center">
    <h1 className="header2">Velkommen, til</h1>
    <h1 className="header1">Bustadklubben</h1>
    <h1 className="header2">i Norheimsund</h1>
    </div>
        
    <div className={`${styles.content_page} content-page`}>
        <div className="content-wrap">
            <div>
                <main className="text content-item light bottomline">
                    Føremålet med Bustadklubben i Norheimsund er å informera om og legga til rette passande bustader for innbyggjarar over 55 år.  
                </main>
            </div>
            
            <div className={`${styles.grid} content-item bottomline`}>
                <div className={styles.grid_obj}>
                    <div className={`${styles.img_container} ${styles.img1}`}></div>
                    <p className="smalltext">Tolohagen</p>
                </div>
                <div className={styles.grid_obj}>
                    <div className={`${styles.img_container} ${styles.img2}`}></div>
                    <p className="smalltext">Tolotunet</p>
                </div>
            </div>
            <div className="content-item">
                <img className={styles.img3} src={img} alt="Oversikt" />
            </div>
        
        </div>

    </div>
    </>
}