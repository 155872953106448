import styles from "../static/styles/tilsals.module.css";
import NorheimsundBilde from "../static/img/Norheimsundbilde.jpg";
export default function Styret() {
    
    return <>
    <div className="bg" style={{ backgroundImage:`url(${NorheimsundBilde})` }}></div>
        <div className="welcome-text center">
            
            <h1 className="header1">Bustadklubben</h1>
            <h1 className="header2">i Norheimsund</h1>
        </div>
    
    <div className="content-page">
        <div className="content-wrap">
            <div className="content">
                
                <div className={`${styles.text1} content-item bottomline`} >post@bustadklubben.no</div>
                <div className={`${styles.grid} content-item`}> 
                    <div className={`${styles.grid_item} div-center`}>
                        <h1 className={`${styles.obj_name} text2`}>Gunnar Eiken</h1>
                        <p className={`${styles.obj_yrke}`}>Leiar</p>
                    </div>
                    <div className={`${styles.grid_item} div-center`}>
                        <h1 className={`${styles.obj_name} text2`}>Anne Marit Åkvik</h1>
                        <p className={`${styles.obj_yrke}`}>Kasserar</p>
                    </div>
                    
                    <div className={`${styles.grid_item} div-center`}>
                        <h1 className={`${styles.obj_name} text2`}>Hans Atle Soldal</h1>
                        <p className={`${styles.obj_yrke}`}>Sekretær</p>
                    </div>
                    <div className={`${styles.grid_item} div-center`}>
                        <h1 className={`${styles.obj_name} text2`}>Ann Kristin Tvedt Brigsten</h1>
                        <p className={`${styles.obj_yrke}`}>Styremedlem</p>
                    </div>
                    <div className={`${styles.grid_item} div-center`}>
                        <h1 className={`${styles.obj_name} text2`}>Anna Brügger</h1>
                        <p className={`${styles.obj_yrke}`}>Styremedlem</p>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>




    </>
}