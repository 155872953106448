import styles from "../static/styles/byggetida1992.module.css";
import NorheimsundBilde from "../static/img/Norheimsundbilde.jpg";

import hatlevbilde1 from "../static/img/Byggetida1992/hatlev bilde 1.jpg";
import hatlevbilde2 from "../static/img/Byggetida1992/hatlev bilde 2.jpg";
import hatlevbilde3 from "../static/img/Byggetida1992/hatlev bilde 3.jpg";
import hatlevbilde4 from "../static/img/Byggetida1992/hatlev bilde 4.jpg";
import hatlevbilde5 from "../static/img/Byggetida1992/hatlev bilde 5.jpg";
import hatlevbilde6 from "../static/img/Byggetida1992/hatlev bilde 6.jpg";
import hatlevbilde7 from "../static/img/Byggetida1992/hatlev bilde 7.jpg";
import hatlevbilde8 from "../static/img/Byggetida1992/hatlev bilde 8.jpg";
import hatlevbilde9 from "../static/img/Byggetida1992/hatlev bilde 9.jpg";
import hatlevbilde10 from "../static/img/Byggetida1992/hatlev bilde 10.jpg";
import hatlevbilde11 from "../static/img/Byggetida1992/hatlev bilde 11.jpg";
import hatlevbilde12 from "../static/img/Byggetida1992/hatlev bilde 12.jpg";
import hatlevbilde13 from "../static/img/Byggetida1992/hatlev bilde 13.jpg";


export default function Medlem() {
    
    return <>
        <div className="bg" style={{ backgroundImage:`url(${NorheimsundBilde})` }}></div>
        <div className="welcome-text center">
            
            <h1 className="header1">Byggetida i 1992</h1>
            
        </div>

        <div className={`${styles.content_page} content-page`}>
            <div className="content-wrap">
                <div className="content">
                
                    <div className={`${styles.grid} content-item bottomline`}>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde1}/>
                            <p className="smalltext">1. Oversikt over tomta.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde2}/>
                            <p className="smalltext">2. Tomta er utsprengd.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde3}/>
                            <p className="smalltext">3. Tomta er planert og klar for støyping.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde4}/>
                            <p className="smalltext">4. Første betongmurar er kome opp.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde5}/>
                            <p className="smalltext">5. Grunnmur vert bygd.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde6}/>
                            <p className="smalltext">6. Grunnmur vert bygd.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde7}/>
                            <p className="smalltext">7. Grunnmur vert bygd.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde8}/>
                            <p className="smalltext">8. Husa vert reiste.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde9}/>
                            <p className="smalltext">9. Husa vert reiste.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde10}/>
                            <p className="smalltext">10. Husa vert reiste.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde11}/>
                            <p className="smalltext">11. Husa vert reiste.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde12}/>
                            <p className="smalltext">12. Husa er reiste, og arbeidet inn er i gang.  Nokre interesserte, med primus motor for prosjektet, Lasse Byrkjeland til høgre med sig.</p>
                        </div>
                        <div className={styles.grid_obj}>
                            <img className={styles.img_container} src={hatlevbilde13}/>
                            <p className="smalltext">13. Arbeid med vegar og uteområde,  Sjølve husa er nær ferdigstillte.</p>
                        </div>
                    </div>


                </div>
                    
            </div>
        </div>      
    </>
}