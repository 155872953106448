import styles from "../static/styles/vedtekter.module.css";

export default function Vedtekter() {
    
    return <>
        <div className="content-page">
            <div className={`${styles.content_wrap}`}>
                
                    <div className={`${styles.vedtekter}`}>
                        <div className={`${styles.content_item}`}>
                            <h1>§ 1. FØREMÅL.</h1>
                            <p>Føremålet med Bustadklubben i Norheimsund er å leggja til rette for personar som har fylt 55 år kan skaffe seg bustad.</p>
                        </div>
                        <div className={`${styles.content_item}`}>
                            <h1>§2. RETTAR OG PLIKTER FOR MEDLEMMENE.</h1>
                            <p>Teikning av medlemskap er avgrensa til personar som er busette i Kvam eller har tilknytning til Kvam. Både ektepar og einslege kan verta medlemmer av Bustadkubben i Norheimsund. Vilkår for medlemskap er at einslege, eller ektefeller/sambuar, har fylt 50 år. Styret kan i særlege tilfelle gje dispensasjon frå reglane om medlemskap. Det vert kravd eit påmeldingsgebyr og ein årleg kontingent. Påmeldingsgebyr  og årskontingent vert fast sette av årsmøte. Alle som eig husvære i bustadområde tilrettelagt av Bustadklubben, er medlemmer i klubben og pliktige til å betala den årlege kontingenten. Medlemskapen gjeld frå den dato påmeldingsgebyr og årskontingent er betalt.</p>
                            <p className="split">Medlemmer som flyttar frå bustad i Bustadklubben får oppretthalda medlemskapen med ansiennitet frå det tidspunktet dei melde seg inn i klubben. Medlem med bustad i “klubben”opprettheld ansienniteten sin i samband med eventuelt byte av bustad innan Bustadklubben.</p>
                        </div>
                        <div className={`${styles.content_item}`}>
                            <h1>§3. REGLAR FOR TILDELING AV BUSTAD, KJØP OG SAL.</h1>
                            <p>Medlemskap gjev rett til kjøp av husvære som Bustadklubben legg til rette for gjennom sitt arbeid, og etter følgjande kriterium i prioritert orden:</p>
                            <p className="italic">For nye prosjekt:</p>
                            <ol>
                                <li>
                                    1.  Etter medlemsansiennitet til medlemmer som er fylt 55 år, eller til uføretryggda som har fylt 50 år. Med «har fylt» meinest det at vedkomande fyller 55 (50) år det året husværet er innflyttingsklart.
                                </li>
                                <li>
                                    2.  Etter medlemsansiennitet til medlemmer som har fylt 50 år og som ikkje kjem inn under gruppa uføretrygda.
                                </li>
                                <li>
                                    3.  Andre interessentar som fyller vilkår for medlemskap i Bustadklubben.
                                </li>
                                <li>
                                    4.  Andre interessentar som ikkje har fylt 50 år.
                                </li>
                            </ol>
                            <p className="italic">For gamle prosjekt:</p>
                            <ol>
                                <li>
                                    1.  Marknadspris, eller den pris som seljar akspepterer, skal leggjast til grunn ved sal av husvære. 
                                </li>
                                <li>
                                    2.  Vikår for kjøp:
                                    <ol className="nestedlist">
                                        <li>Kjøparen skal sjølv bu i husværet.</li>
                                        <li>Ingen kan eiga meir enn 1 – eitt – husvære som ligg under Bustadklubben.</li>
                                    </ol>     
                                </li>
                                <li>
                                    1.  Eigar, eller den som overtar husvære ved arv, kan med godkjenning av styret i Bustadklubben leiga ut husværet i inntil 2 år. Ved utleige skal medlemmer i Bustadklubben prioriterast, deretter personar som fyller krava til medlemskap i  Bustadklubben. Leigekontrakt skal godkjennast av styret i Bustadklubben etter at den enkelte sameige ved styret har gjeve samtykke.
                                </li>
                                <li>
                                    2.  Styret i Bustadklubben kan i særskilde høve gi dispensasjon frå kravet om alder etter at den enkelte sameige ved styret har gjeve samtykke.
                                </li>
                                <li>3.  Prosedyre ved sal:
                                    <p>Seljar gjev skriftleg melding til Bustadklubben om at husvære er til sals.</p>
                                    <p>Styret i Bustadklubben er ansvarleg for at dei av Bustadklubben sine medlemmer som  ikkje har husvære i Bustadklubben, får skriftleg melding om at det aktuelle husværet er til sals med melding om at medlemmer som kan ha interesse av dette husværet melder skriftleg frå til styret innan 5 dagar.</p>
                                    <p>Seljar skal før sal kan godkjennast, gjera medlemmene i Bustadklubben som har meldt interesse, skriftleg melding om høgaste innkomne bod (marknadspris) eller den pris seljaren forlangar.</p>
                                    <p>Medlem i Bustadklubben må innan 10 dagar etter seljaren sitt brev er postlagt, gje  skriftleg melding til seljaren om at ein bind seg til å kjøpa husværet med heimel i forkjøpsretten. Melding om bruk av forkjøpsretten om vera postlagt seinast  ved utløp av fristen.</p>
                                    <p>Styret i Bustadklubben skal godkjenna alle sal av husvære før  salet kan iverksetjast. Dersom fleire medlemmer har nytta forkjøpsretten, avgjer styret i heimel i ansiennitetsreglementet kven som skal få tilslaget.</p>
                                </li>
                                <li>4.  Evt. dissens i styret ved sal/utleige av husvære vert avgjort ved simpelt fleirtal.</li>
                            </ol>
                        </div>
                        <div className={`${styles.content_item}`}>
                            <h1>§4. STYRET.</h1>
                            <p>Til årsmøtet – som skal haldast kvart år innan utgangen av mars månad –vert medlemmene innkalla med 3 vekers varsel. Forslag som ein ønskjer å handsama på årsmøtet, må vera styret i hende seinast 14 dagar før årsmøtet.</p>
                            <p>På årsmøtet skal følgjande handsamast: </p>
                            <ol>
                                <li>
                                    1. Årsmelding. 
                                </li>
                                <li>
                                    2. Rekneskap
                                </li>
                                <li>
                                    3. Fastsetjing av påmeldingsavgift og årskontingent. 
                                </li>
                                <li>
                                    4. Innkomne framlegg
                                </li>
                                <li>
                                    5. Val av formann/leiar, styremedlemmer, varamedlemmer og 1 revisor.
                                </li>
                                <li>
                                    6. Val av 3 medlemmer til  valkomite + 1 varamedlem.
                                </li>
                            </ol>
                        </div>
                        <div className={`${styles.content_item}`}>
                            <h1>§5. ÅRSMØTE.</h1>
                            <p>Styret i Bustadklubben i Norheimsund har 5 personar med 2 vararepresentanter. Styremedlemmene vert valde av årsmøtet for 2 år om gongen. Etter eitt år vert 2 av medlemmene trekte ut, slik at det vert høve til nyval. Firmann/leiar vert vald ved særskilt va av årsmøtet. Vararepresentanter vert valde for eitt år.</p>
                            <p>Sameigene i Bustadklubben skal få tilsendt sakliste og godkjent referat frå alle styremøter.</p>
                        </div>
                        <div className={`${styles.content_item}`}>
                            <h1>§6. VEDTEKTSENDRINGAR.</h1>
                            <p>Berre årsmøtet kan vedta endringar i vedtektene. For at vedtektsendringa skal vera gyldig, skal endringa vedtakast på ordinært årsmøte og i tillegg på ekstraordinært årsmøte som skal haldast tidlegast 3 veker og seinast 2 månader etter ordinært årsmøte. Det ekstraordinære årsmøtet skal kunngjerast på same måte som ordinære årsmøte. Ekstraordinært årsmøte skal ikkje ha andre saker enn vedtektsendring som sak.</p>
                            <p>Framlegg til endring av vedtektene skal vera gjort kjent for medlemmene i brev seinast 7 dagar før årsmøtet.</p>
                            <p>Vedtektsendringar skal gjerast med 2/3 fleirtal rekna ut frå frammøtte medlemmer. Vedtektsendringa trer i kraft straks endeleg vedtak om endring i vedtekta vert gjort.</p>
                            <p>Vedtektene for Bustadklubben og for den enkelte sameige må vera i samsvar med retningslinene i seksjonseigarlova.</p>
                            <p>Ved evt. motsetjingar mellom vedtekter for den enkelte sameige og desse vedtektene for Bustadklubben for det som gjeld reglane for forkjøpsrett og utleige og som ikkje er regulert av seksjonseigarlova, gjeld vedtektene for Bustadklubben.</p>
                        </div>
                    </div>
                
                    
            </div>
                
        </div>

    </>
}