import { Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer"
import Home from "./pages/Home";
import Styret from "./pages/Styret";
import Info from "./pages/Info";
import Medlem from "./pages/Medlem";
import Tilsals from "./pages/Tilsals";
import Vedtekter from "./pages/Vedtekter";
import Byggetida1992 from "./pages/Byggetida-1992";



function App() {
  //const head = document.getElementsByTagName('head');
  //const prevStyles = head .getElementsByTagName('style');
  //console.log(prevStyles);
  return (
    <>
      <Navbar />
      
      <Routes>
        <Route path="/styret" element={<Styret />} />
        <Route path="/info" element={<Info />} />
        <Route path="/medlem" element={<Medlem />} />
        <Route path="/vedtekter" element={<Vedtekter />} />
        <Route path="/tilsals" element={<Tilsals />} />
        <Route path="/" element={<Home />} />
        <Route path="/byggetida-1992" element={<Byggetida1992 />} />
        
        
      </Routes>
      
      
      <Footer />
    </>
   
  );
}

export default App;
