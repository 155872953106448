import styles from "../static/styles/medlem.module.css";
import NorheimsundBilde from "../static/img/Norheimsundbilde.jpg";
export default function Medlem() {
    
    return <>
        <div className="bg" style={{ backgroundImage:`url(${NorheimsundBilde})` }}></div>
        <div className="welcome-text center">
            
            <h1 className="header1">Bli med i</h1>
            <h1 className="header1">Bustadklubben</h1>
        </div>

        <div className={`${styles.content_page} content-page`}>
            <div className="content-wrap">
                <div className="content">
                    <div className="medlemtext">
                        
                        <div className="box1">
                            <h1>Innmeldingskontigent kr 250,-</h1>
                            <p>Kontakt: <a className={`${styles.mail}`} href="mailto:anne.aakvik@kvamnet.no">anne.aakvik@kvamnet.no</a></p> 
                        </div>
                        <div className="box2">
                            <h1>Årskontigent kr 300,-</h1>
                            <p>Betalast til konto: 3530.07.01025</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>      
    </>
}