import styles from "./static/styles/index.module.css";
import { isHomePage } from "./pages/Home";

export default function Footer() {
    if (isHomePage === true) {
        return <>
    <div className={`center footer ${styles.footer}`} style={{display: "none"}}>
        <div>
            <p className="sted">Bustadklubben i Norheimsund, Norge</p>  
            <p className="mail">post@bustadklubben.no</p>    
        </div>
            
    </div>
    </>
    }
    return <>
    <div className={`center footer ${styles.footer}`}>
        <div>
            <p className="sted">Bustadklubben i Norheimsund, Norge</p>  
            <p className="mail">post@bustadklubben.no</p>    
        </div>
            
    </div>
    </>
}