

import styles from "../static/styles/info.module.css";
import samplePDF1 from "./download/15.09.2020-Referat-Styremote.pdf";
import samplePDF6 from "./download/24.02.2022-Referat-Styremote.pdf";
import samplePDF2 from "./download/aarsmotereferat-2021.pdf";
import samplePDF3 from "./download/aarsmotereferat-2020.pdf";
import sampleODT1 from "./download/Vedlegg-Styremote-18.11.21.odt";
import samplePDF4 from "./download/aarsmotereferat-2019.pdf";
import Årsøte2023 from "./download/referatårsmøte-22-03-2023.pdf";
import Styremøte28022023 from "./download/Referat styremøte 28022023.odt";
import samplePDF5 from "./download/Årsmelding-for-Bustadklubben-2018.pdf";
import FRAMTIDSFULLMAKT from "./download/Framtidsfullmakt.pdf";
import TESTAMENT from "./download/Testament.pdf";
import INNKALLING from "./download/Innkalling-aarsmote.pdf";
import Velferdsteknologi from "./download/Temakveld+Velferdstek.odp";
import _16oktober2023 from "./download/Referat frå styremøte i Bustadklubben 16. oktober 2023.pdf";
import _24mai2023 from "./download/Referat frå styremøte i Bustadklubben 24. mai 2023.pdf";
import _årsmøte2024 from "./download/2024/Årsmøte 2024 filer.zip";
import Styremøte20022024 from "./download/Referat frå styremøte 20. februar 2024.pdf";




//NB! CONVERT DOCX AND ODT FILES TO PDF ONLINE 
//NEW DOWNLOAD FILE USE: import file as seen above and use like so in <a> tag: href={samplePDF1} target="_blank" rel="noreferrer"

//const Downloader = ({ files }) => {
//    return (
//        <div style={{ display: 'none' }}>
//            {
//                files?.map(({ url }) => <iframe src={url} />)
//            }
//        </div>
//    );
//}

export default function Info() {
    
    return <>
        <div className="content-page">
            <div className="content-wrap">
                <div className="content">
                    <div className={`${styles.this_header}`}><div className="header1">Nyttig informasjon</div></div>
                    <div className={`${styles.epost} div-center content-item`}>post@bustadklubben.no</div>
                    <div className={`${styles.kategorier}`}>
                        <div className={`${styles.kategori} text2 ${styles.content_item}`}>
                            <h1>Styremøtereferat</h1>
                            <h1 className="light">2024</h1>
                            <div className={`${styles.btn_list}`}>
                                <button><a href={_årsmøte2024} target="_blank" download="Årsmøte-2024-filer.zip" rel="noreferrer">ÅRSMØTE 2024</a></button>
                                <button><a href={Styremøte20022024} target="_blank" download="Referat frå styremøte 20. februar 2024" rel="noreferrer">STYREMØTE 20.02.2024</a></button>
                                
                            </div>
                            <h1 className="light">2023</h1>
                            <div className={`${styles.btn_list}`}>
                                <button><a href={_16oktober2023} target="_blank" rel="noreferrer">STYREMØTE 16.10.2023</a></button>
                                <button><a href={_24mai2023} target="_blank" rel="noreferrer">STYREMØTE 24.05.2023</a></button>
                                <button><a href={Årsøte2023} target="_blank" rel="noreferrer">ÅRSMØTE 22.03.2023</a></button>
                                <button><a href={Styremøte28022023} target="_blank" rel="noreferrer">STYREMØTE 28.02.2023</a></button>
                                
                            </div>
                            <h1 className="light">2022</h1>
                            <div className={`${styles.btn_list}`}>
                                <button><a href={samplePDF6} target="_blank" rel="noreferrer">STYREMØTE 24.02.22</a></button>
                                
                            </div>
                            <h1 className="light">2021</h1>
                            <div className={`${styles.btn_list}`}>    
                                <button><a href={sampleODT1} target="_blank" rel="noreferrer">STYREMØTE 18.11.21</a></button>
                                <button><a href={samplePDF2} target="_blank" rel="noreferrer" >REFERAT ÅRSMØTE 2021</a></button>
                                
                            </div>
                            <h1 className="light">2020</h1>
                            <div className={`${styles.btn_list}`}>   
        
                                <button><a href={samplePDF1} target="_blank" rel="noreferrer">STYREMØTE 15.09.20</a></button>
                                <button><a href="/info">STYREMØTE 08.12.20</a><div>ingen fil</div></button>
                                <button><a href="/info">STYREMØTE 27.10.20</a><div>ingen fil</div></button> 
                                <button><a href={samplePDF3} target="_blank" rel="noreferrer">REFERAT ÅRSMØTE 2020</a></button>  
                            </div>
                            <h1 className="light">2019</h1>    
                            <div className={`${styles.btn_list}`}>
                                
                                  
                                <button><a href={samplePDF4} target="_blank" rel="noreferrer">REFERAT ÅRSMØTE 2019</a></button>
                            </div>
                        </div>
                        <div className={`text2 ${styles.content_item}`}>
                            <h1>Årsmeldingar</h1>
                            <div className={`${styles.btn_list}`}>
                                <button><a href={samplePDF5} target="_blank" rel="noreferrer">ÅRSMELDING 2018</a></button>
                            </div>
                        </div>
                        <div className={`${styles.kategori} text2 ${styles.content_item}`}>
                            <h1>Andre Dokument</h1>
                            <div className={`${styles.btn_list}`}>
                                <button><a href={FRAMTIDSFULLMAKT} target="_blank" rel="noreferrer">FRAMTIDSFULLMAKT</a></button>
                                <button><a href="/byggetida-1992">Byggetida i 1992</a></button>
                                <button><a href={TESTAMENT} target="_blank" rel="noreferrer">TESTAMENT</a></button>    
                                <button><a href={INNKALLING} target="_blank" rel="noreferrer">INKALLING TIL ÅRSMØTE</a></button>
                            </div>
                        </div>
                        <div className={`${styles.kategori} text2 ${styles.content_item}`}>
                            <h1>Velferdsteknologi</h1>
                            <div className={`${styles.btn_list}`}>
                                <button><a href={Velferdsteknologi} target="_blank" rel="noreferrer">KVAM HERAD VED IT KONSULENT BERSTAD HELD FOREDRAG</a></button>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </>
}