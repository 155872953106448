import styles from "../static/styles/tilsals.module.css";
import NorheimsundBilde from "../static/img/Norheimsundbilde.jpg";
export default function Medlem() {
    
    return <>
        <div className="bg" style={{ backgroundImage:`url(${NorheimsundBilde})` }}></div>
        <div className="background-img">
            <div className="welcome-text center">
                
                <h1 className="header1">Til sals</h1>
                <h1 className="header2">i Norheimsund</h1>
            </div>
        </div>
        <div className="content-page">
            <div className="content-wrap">
                <div className={`${styles.content}`}>
                    <div className="tilsalgs">
                        <p className="div-center content-item text">Ingen til salgs for øyeblikket</p>
                    </div>
                    <div className="grid">






                    </div>
                </div>   
            </div>
        </div>    
    </>
}